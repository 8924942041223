import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async searchBrand({ rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('campaign/partner-search', { params: form, headers: headers})
        return response
    },


    async getBrands({ rootGetters}, form) {
        const headers =  {
            'Content-Type': 'application/json',
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('partner/list',{ params: form, headers: headers})
        return response
    },

    async createBrand({ rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'application/json',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('partner/create',form, headers)
        return response
    },
    
    async updateBrand({ rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'application/json',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('partner/update',form, headers)
        return response
    },
    
    async detailBrand({ rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }
        const response = await axios.get('partner/detail',{ params: form, headers: headers})
        return response
    },
    
    async deleteBrand({ rootGetters}, form) {
        const headers =  {
            headers:{
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }
        const response = await axios.post('partner/delete',form, headers)
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};

import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async searchCategory({ rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('campaign/category-search', { params: form, headers: headers})
        return response
    },

    async getCategories({ rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        console.log(headers,"TOKEN")

        const response = await axios.get('category-list',{ params: form, headers: headers})
        return response
    },

    async createCategory({ rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('category-create',form, headers)
        return response
    },
    
    async updateCategory({ rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.put('category-update',form, headers)
        return response
    },
    
    async detailCategory({ rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }
        
        const response = await axios.get('category-detail',{ params: form, headers: headers})
        return response
    },
    
    async deleteCategory({ rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }
        
        const response = await axios.delete('category-delete',{ params: form, headers: headers})
        return response
    },

    async activateCategory({ rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('category-activate',form, headers)
        return response
    },

    async deactivateCategory({ rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('category-deactivate',form, headers)
        return response
    },


};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};

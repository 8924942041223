import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {

    async getAffiliateGroups({ rootGetters}, form) {
        const headers =  {
            'Content-Type': 'application/json',
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('affiliate-group/list',{ params: form, headers: headers})
        return response
    },

    async createAffiliateGroup({ rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'application/json',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('affiliate-group/create',form, headers)
        return response
    },
    
    async updateAffiliateGroup({ rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'application/json',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('affiliate-group/update',form, headers)
        return response
    },
    
    async detailAffiliateGroup({ rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }
        const response = await axios.get('affiliate-group/detail',{ params: form, headers: headers})
        return response
    },
    
    async deleteBrand({ rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }
        const response = await axios.post('partner/delete',form, headers)
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
